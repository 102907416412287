<template>
  <div class="fill-height black px-4 py-12">
    <v-container
      fluid
    >
      <base-img
        v-if="showMainImage"
        class="img_project"
        height="950px"
        width="28%"
        aspect-ratio="1"
        :src="siteUrl + portfolio.main_icon"
      />
      <base-img
        v-if="!showMainImage"
        class=""
        height="100%"
        width="100%"
        :src="siteUrl + portfolio.main_icon"
      />
    </v-container>
    <v-responsive
      class="d-flex mx-auto"
      height="460px"
      :width="$vuetify.breakpoint.width >= 768 ? '45%' : '100%'"
    >
      <base-heading
        class="heading_title_page mb-0"
        :style="$vuetify.breakpoint.width >= 768 ? 'font-size: 70px !important' : 'font-size: 28px !important'"
        :title="portfolio.title"
        :align="$vuetify.breakpoint.width >= 768 ? 'left' : 'center'"
      />
      <base-subheading
        :title="portfolio.description"
        weight="medium"
        :style="$vuetify.breakpoint.width >= 768 ? 'font-size: 18px !important; line-height: inherit; color: #BDBDBD !important; font-weight: 400 !important;'
        : 'font-size: 14px !important; color: #BDBDBD !important; font-weight: 400 !important; line-height: normal;'"
        align="left"
      />
    </v-responsive>
    <v-responsive
      class="d-flex mx-auto"
      min-height="300"
      :width="$vuetify.breakpoint.width >= 768 ? '45%' : '100%'"
    >
      <base-subtitle
        :title="$t('stack')"
        class="text-capitalize"
        weight="700"
        :style="$vuetify.breakpoint.width >= 768 ? 'color: #0A8C4C !important; line-height: 3; font-size: 25px !important;font-weight: bold;'
        : 'color: #0A8C4C !important; line-height: 3; font-size: 22px !important;font-weight: bold;'"
      ></base-subtitle>
      <div style="margin-left: -2%">
        <v-row>
          <v-card
            v-for="stack in portfolio.stacks"
            :key="stack.title"
            class="pr-14 pl-8 mt-6"
            style="border-right: 2px solid #4F4F4F; border-radius: 0"
          >
            <img
              :src="siteUrl + stack.icon"
              height="42"
              width="42"
            >
            <base-subtitle
              :title="stack.title"
              :style="$vuetify.breakpoint.width >= 768 ? 'font-style: normal !important;font-weight: bold !important;font-size: 25px !important;line-height: 30px !important;'
              : 'font-style: normal !important;font-weight: bold !important;font-size: 22px !important;line-height: 30px !important;'"
              class="text-uppercase mt-1"
            />
          </v-card>
        </v-row>
      </div>
      <v-container>
        <v-row>
          <v-card
            v-for="(link, index) in portfolio.portfolio_links"
            :key="index"
            class="mr-4 mt-10"
          >
            <div>
              <a
                v-if="link.type === 'play'"
                :href="link.url"
              >
                <img
                  :src="require('@/assets/play_market.svg')"
                  alt=""
                  height="100%"
                  width="100%"
                >
              </a>
            </div>
            <div>
              <a
                v-if="link.type === 'appstore'"
                :href="link.url"
              >
                <img
                  :src="require('@/assets/app_store.svg')"
                  alt=""
                  height="100%"
                  width="100%"
                >
              </a>
            </div>
            <div>
              <a
                v-if="link.type === 'web'"
                :href="link.url"
              >
                <img
                  :src="require('@/assets/web.png')"
                  alt=""
                  height="100%"
                  width="100%"
                >
              </a>
            </div>
          </v-card>
        </v-row>
      </v-container>
    </v-responsive>
    <div
      class="d-flex flex-wrap justify-center "
    >
      <base-heading
        v-if="this.items.length > 0"
        title="gallery"
        class="text-uppercase"
      />
    </div>
    <div class="row">
      <div class="col-12">
        <masonryWall
          :column-width="columnWidth"
          :gap="gap"
          :rtl="rtl"
          :items="slicePortfolio ()"
        >
          <template v-slot:default="{ item }">
            <div
              class="item"
              @click="viewZoom(item.url)"
            >
              <img
                width="300px"
                :src="siteUrl + item.url"
                alt=""
              />
            </div>
          </template>
        </masonryWall>
      </div>
    </div>
    <div
      v-if="isNeedShowMore()"
      class="d-flex flex-wrap justify-center"
    >
      <v-btn
        width="10%"
        min-height="60"
        class="btn_more mt-lg-9"
        @click="clickShowMore()"
      >
        {{ $t('showMore') }}
      </v-btn>
    </div>
    <v-dialog
      v-model="visibleDialog"
      persistent
      max-width="300"
    >
      <v-card>

        <v-img :src="siteUrl + this.imageUrlDialog"></v-img>
        <v-card-actions
          dark
          class="justify-end"
        >
          <v-btn
            text
            @click="closeDialog"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import BaseHeading from '../../components/base/Heading'
  import { siteUrl } from '../../config'

  export default {
    name: 'PortfolioPage',
    components: { BaseHeading },
    props: {
      id: {
        type: [String, Number],
        required: true,
      },
    },
    data: () => ({
      rows: 3,
      siteUrl: siteUrl,
      items: [],
      columnWidth: 300,
      gap: 8,
      rtl: false,
      visibleDialog: false,
      imageUrlDialog: null,
    }),
    computed: {
      showMainImage () {
        return this.$vuetify.breakpoint.width >= 768
      },
      ...mapGetters({
        portfolio: 'portfolio/portfolio',
      }),
    },
    mounted () {
      this.$store.dispatch('portfolio/fetchPortfolio', { id: this.id })
    },
    updated () {
      this.items = this.portfolio.portfolio_gallery_images
    },
    methods: {
      viewZoom (url) {
        this.visibleDialog = true
        this.imageUrlDialog = url
      },
      closeDialog () {
        this.visibleDialog = false
      },
      slicePortfolio () {
        const minIndex = Math.min(this.items.length, this.rows * 2)
        console.log('minIndex: ' + minIndex + 'this.portfolios.length :' + this.items.length)
        return this.items.slice(0, minIndex)
      },
      isNeedShowMore () {
        return (this.rows * 2 < this.items.length)
      },
      clickShowMore () {
        this.rows++
      },
    },
  }
</script>

<style lang="sass">
.heading_title_page
  color: #F2F2F2 !important
  font-weight: 500 !important
  line-height: 85px !important

.img_project
  position: absolute
  right: -1%
  top: -13%
  opacity: 0.55
  z-index: 5

.btn_more
  border: 2px solid #0A8C4C !important
  background: transparent
#gallery
  max-width: 1500px

.item
  border-radius: 3px
  padding: 3px
</style>
